body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.icon-mouse-hover {}

/* Defina a animação de batimento (beat) */
@keyframes beat {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

/* Aplique a animação no ícone com a classe icon-beat quando o mouse estiver sobre o elemento com a classe icon-mouse-hover */
.icon-mouse-hover .icon-beat {
  transform-origin: center;
  /* Define o ponto de origem da transformação como o centro do elemento */
  transition: transform 0.5s ease;
  /* Adiciona uma transição suave para a transformação */
}

/* Quando o mouse estiver sobre o elemento com a classe icon-mouse-hover, aplique a transformação de escala */
.icon-mouse-hover:hover .icon-beat {
  transform: scale(1.4);
  /* Aumenta o tamanho do ícone */
}

/* Quando o mouse sair do elemento com a classe icon-mouse-hover, retoma o tamanho normal do ícone */
.icon-mouse-hover:hover .icon-beat:hover {
  transform: scale(1);
  /* Volta ao tamanho normal do ícone */
}